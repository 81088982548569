
(() => {
   'use strict'
    
   const preloader = document.getElementById('preloader')

   window.onload = () => {      
      setTimeout(() => {
         preloader.classList.add('preloader_hidden')
         document.body.classList.remove('body_loading')
      }, 100);

      setTimeout(() => {
         preloader.remove()
      }, 3000);
   }

   const doors = document.querySelectorAll('.nav')

   for (let i = 0; i < doors.length; i++) {
      const el = doors[i];

      el.addEventListener('click', e => {
         e.preventDefault()

         changeSection(e.currentTarget)
      })
   }

   const modalLinks = document.querySelectorAll('.modalSelector')
   const modalCloseBtns = document.querySelectorAll('.modal__close')

   modalLinks.forEach(el => {
      el.addEventListener('click', e => {
         e.preventDefault()

         const target = e.currentTarget.getAttribute('href')
         const targetModal = document.querySelector(target)

         if(target && targetModal) {
            openModal(targetModal)
         }
         
      })
   })

   modalCloseBtns.forEach(el => {
      el.addEventListener('click', e => {
         e.preventDefault()

         const target = e.target.closest('.modal')

         closeModal(target)
      })
   })   

   window.addEventListener('click', e => {
      if(e.target.classList.contains('modal')) {
         closeModal(e.target)
      }
   })

   const robotIcon = document.querySelector('.robot__icon')
   const robotTipClose = document.querySelector('.robot__close')
   
   robotIcon.addEventListener('click', showRobotTip)
   robotTipClose.addEventListener('click', hideRobotTip)

   const fasadIcon = document.querySelector('.fasad__icon')
   // const fasadTipClose = document.querySelector('.fasad__close')
   
   fasadIcon.addEventListener('click', openFasadTip)
   // fasadTipClose.addEventListener('click', closeFasadTip)
   window.addEventListener('click', e => {
      if(!e.target.classList.contains('fasad__tip_shown') && !e.target.classList.contains('fasad__icon')) {
         closeFasadTip()
      }
   })

   // Functions

   function changeSection (link) {
      const parentSection = link.closest('.section')
      const target = link.getAttribute('href')
      const targetSection = document.querySelector(target)
      
      if(target && targetSection) {
         parentSection.classList.remove('section_active')
         targetSection.classList.add('section_active')
      }
   }
   function openModal (target) {
      target.style.zIndex = 20
      
      setTimeout(() => {
         target.classList.add('modal_open')
         document.body.classList.add('open_modal')
      }, 50)
   }
   function closeModal (target) {
      target.classList.remove('modal_open')
      document.body.classList.remove('open_modal')

      setTimeout(() => {
         target.style.zIndex = 0
      }, 500);
   }
   function showRobotTip () {
      const icon = document.querySelector('.robot__icon')
      const tip = document.querySelector('.robot__tip')
      
      tip.style.height = 'auto'
      
      setTimeout(() => {
         icon.classList.add('robot__icon_hidden')
         tip.classList.add('robot__tip_shown')
      }, 50)
   }
   function hideRobotTip () {
      const icon = document.querySelector('.robot__icon')
      const tip = document.querySelector('.robot__tip')

      icon.classList.remove('robot__icon_hidden')
      tip.classList.remove('robot__tip_shown')

      setTimeout(() => {
         tip.style.height = 0
      }, 500);
   }
   function openFasadTip() {
      const icon = document.querySelector('.fasad__icon')
      const tip = document.querySelector('.fasad__tip')

      tip.classList.add('fasad__tip_shown')
      icon.classList.add('fasad__icon_hidden')
   }
   function closeFasadTip() {
      const icon = document.querySelector('.fasad__icon')
      const tip = document.querySelector('.fasad__tip')

      tip.classList.remove('fasad__tip_shown')
      icon.classList.remove('fasad__icon_hidden')
   }

   function newElement(name, attr = {}) {
      if (!name || typeof(name) !== 'string') {
         return;
      }
      const element = document.createElement(name)

      for (const [key, val] of Object.entries(attr)) {
         element.setAttribute(key, val)
      }
       
      return element;
   }
   // function addImgLinks() {
   //    const images = [
   //       {
   //          href: '<?=$publicAssetUrl?>/assets/frontend/home/img/candidates.jpg',
   //          rel: 'preload',
   //          as: 'image'
   //       },
   //       {
   //          href: './img/partners.jpg',
   //          rel: 'preload',
   //          as: 'image'
   //       },
   //       {
   //          href: './img/liaders.jpg',
   //          rel: 'preload',
   //          as: 'image'
   //       },
   //    ]

   //    images.map(img => {
   //       document.querySelector('body').append(newElement('link', img))
   //    })
   // }

})();